<template src="./AdminConfirmationModal.html">
</template>

<script>
export default {
  name: 'admin-confirmation-modal',
  data() {
    return {
      vButtons: "",
    };
  },
  props: {
    openModal: { type: Boolean, defaul: false }
  },
  methods: {
    closePopup(){
      this.$emit("closeModal", false);
    },
    confirm() {
      this.$emit("confirmModal")
    }
  }

}
</script>

<style lang="scss" scoped src="./AdminConfirmationModal.scss">
</style>

import { nodeInstance } from "@/plugins/axios";
import store from "@/store";

const categoryService = {};

categoryService.getCategories = () => {
  return nodeInstance.get("/api/category");
};

categoryService.getCategoriesWhitSubcategory = () => {
  return nodeInstance.get("/api/category/subcategory");
};

categoryService.createCategory = params => {
  params.token = store.getters.getToken;
  return nodeInstance.post("/api/category", params);
};

categoryService.updateCategory = (params, id) => {
  params.token = store.getters.getToken;
  return nodeInstance.patch(`/api/category/${id}`, params);
};

categoryService.deleteCategory = id => {
  return nodeInstance.post(`/api/category/${id}`, {
    token: store.getters.getToken
  });
};

categoryService.getSubcategories = () => {
  return nodeInstance.get("/api/subCategory");
};

categoryService.createSubcategory = params => {
  params.token = store.getters.getToken;
  return nodeInstance.post("/api/subCategory", params);
};

categoryService.updateSubcategory = (params, id) => {
  params.token = store.getters.getToken;
  return nodeInstance.patch(`/api/subCategory/${id}`, params );
};

categoryService.updateCategory = (params, id) => {
  params.token = store.getters.getToken;
  return nodeInstance.patch(`/api/category/${id}`, params );
};

categoryService.deleteSubcategory = id => {
  return nodeInstance.post(`/api/subCategory/${id}`, {
    token: store.getters.getToken
  });
};

export default categoryService;

<template src="./AdminAcademicCourses.html"></template>

<script>
import coursesService from "@/services/Courses";
import AdminConfirmationModal from "./../../AdminConfirmationModal/AdminConfirmationModal.vue";
import categoryService from "@/services/Category";
import { putFile } from "@/services/files";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "admin-academic-courses",
  data() {
    return {
      subcategoryList: null,
      category: null,
      subcategoryInit: null,
      subcategory: null,
      openModal: false,
      courseID: null,
      courseName: null,
      checkedUpFile: false,
      imageCourse: null,
      lastUrlImageCourse: "",
      descriptionImage: "",
      categories: [],
      photoBase64: null,
      style: { width: "200px" },
      isCardModalActive: false,
      photoFile: null,
    };
  },
  components: { AdminConfirmationModal, Cropper },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      this.categories = await categoryService.getCategoriesWhitSubcategory();
    },
    openAdminModal() {
      if (!this.descriptionImage && this.checkedUpFile) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta descripcion",
          position: "is-bottom",
          type: "is-danger",
        });
        return false;
      }
      this.openModal = true;
    },
    closeModal() {
      this.openModal = false;
    },
    restoreData() {
      this.imageCourse = null;
      this.lastUrlImageCourse = "";
      this.descriptionImage = "";
      this.subcategoryList = null;
      this.category = null;
      this.subcategory = null;
      this.courseID = null;
      this.courseName = null;
    },
    async confirm() {
      this.$store.dispatch("fetchLoading", true);
      if (this.imageCourse && this.descriptionImage) {
        let url = null;
        try {
          url = await putFile(
            this.imageCourse,
            "Groups",
            `${this.imageCourse.name}`
          );
          await coursesService.updateCourseImage(this.courseID, {
            url,
            description: this.descriptionImage,
          });
        } catch (error) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando el archivo",
            position: "is-bottom",
            type: "is-danger",
          });
          console.error(error);
        }
      } else if (this.descriptionImage) {
        try {
          await coursesService.updateCourseImage(this.courseID, {
            description: this.descriptionImage,
          });
        } catch (error) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error cargando la descipcion",
            position: "is-bottom",
            type: "is-danger",
          });
          console.error(error);
        }
      }
      if (this.category) {
        try {
          await coursesService.updateCourseCategoria(
            this.courseID,
            this.category.id
          );
        } catch (error) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error al actualizar la categoría",
            position: "is-bottom",
            type: "is-danger",
          });
          console.error(error);
        }
      }
      if (this.subcategory) {
        try {
          await coursesService.updateCourseSubcategoria(
            this.courseID,
            this.subcategory.id
          );
        } catch (error) {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Error al actualizar la subcategoría",
            position: "is-bottom",
            type: "is-danger",
          });
          console.error(error);
        }
      }

      this.closeModal();
      this.restoreData();
      this.$buefy.toast.open({
        duration: 5000,
        message: "El curso fue modificado con éxito",
        position: "is-bottom",
        type: "is-success",
      });
      this.$store.dispatch("fetchLoading", false);
    },
    async findCourse() {
      try {
        const course = await coursesService.findCourse(this.courseID);
        this.subcategory = course.subCategoria.find(
          (subcategory) => subcategory.id === course.curso.subcategory_id
        );
        this.subcategoryInit = this.subcategory;
        this.category = this.categories.find(
          (category) => category.id == course.categoria.id
        );
        this.courseName = course.curso.nombre;
        this.subcategoryList = course.subCategoria;
        this.lastUrlImageCourse = course.curso.lnk_imagen;
        this.descriptionImage = course.curso.descripcion_imagen;
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
        this.restoreData();
      }
    },
    handleCategory() {
      let category = this.categories.find(
        (category) => parseInt(category.id) === parseInt(this.category.id)
      );
      this.subcategoryList = category.subcategory;
      this.subcategory = this.subcategoryList.find(
        (subcategory) =>
          parseInt(subcategory.id) === parseInt(this.subcategoryInit.id)
      );
    },
    imageToBase64() {
      this.style.width = "40vw";
      var reader = new FileReader();
      reader.readAsDataURL(this.imageCourse);
      reader.onload = () => {
        this.photoBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.error("Error on image to base 64:", error);
      };
      this.isCardModalActive = true;
    },
    cropImage() {
      const result = this.$refs.cropper.getResult().canvas.toDataURL();
      this.photoFile = this.dataURLtoFile(result);
      this.imageCourse = this.photoFile;
      this.isCardModalActive = false;
    },
    dataURLtoFile(dataurl) {
      //Convert base64 to file
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], {
        type: mime,
      });
    },
  },
  computed: {
    courseImage() {
      if (this.imageCourse) {
        return URL.createObjectURL(this.imageCourse);
      }
      if (this.lastUrlImageCourse) {
        if (this.lastUrlImageCourse.substr(0, 4) === "http") {
          return this.lastUrlImageCourse;
        } else {
          return `${process.env.VUE_APP_ARROBA}/images/cursos/${this.lastUrlImageCourse}`;
        }
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped src="./AdminAcademicCourses.scss"></style>

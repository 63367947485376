import { nodeInstance, phpInstance } from "@/plugins/axios";
import { storage } from "../../plugins/firebase";
import store from "@/store";

const coursesService = {};

coursesService.getAllCoursesByInstitution = () => {
  return phpInstance.get(
    `/institutions/courses/all`,
  );
};
coursesService.getAllCoursesByUniversity= (id) => {
  return phpInstance.get(
    `/courses/institutions/${id}`,
  );
};
coursesService.getCourseProgress = (codigo) => {
  return phpInstance.get(
    `/moodle/courses/user/codigo/${codigo}`,
  );
};
coursesService.getCourseUrl = (codigo) => {
  return phpInstance.get(
    `/moodle/courseUrl/${codigo}`,
  );
};
coursesService.getAllOvis = () => {
  return nodeInstance.get(
    `/api/ovis`,
  );
};
coursesService.getAllOvisMyUser = () => {
  return nodeInstance.get(
    `/api/ovis/myUser`,
  );
};
coursesService.countCoursesHaveNotEnrolment = () => {
  return phpInstance.get(
    `/courses/formEnrollCourse/countCorsesHaveNotEnrolment`,
  );
};
coursesService.countCoursesHaveNotEnrolmentNames = () => {
  return phpInstance.get(
    `/courses/formEnrollCourse/countCorsesHaveNotEnrolmentNames`,
  );
};
coursesService.getCoursesRecommendations = () => {
  const params = {
    skip: 0,
    take: 15
  };
  return phpInstance.get(
    `/courses/user/educationContinue/list/`, {params}
  );
};

coursesService.relationMatriculeStudentWhitTeacher = (userTeacherId) => {
  return phpInstance.get(`/courses/relationMatriculeStudentWhitTeacher/${userTeacherId}`);
};

coursesService.getAllCoursesRecommendations = (type) => {
  return phpInstance.get(`/courses/recommendation/getByType/${type}`);
};
coursesService.getRecommendedCourseById = (id) => {
  return phpInstance.get(`/courses/recommendation/${id}`);
};
coursesService.getCourseFormUserEnrolmentCourse = (id) => {
  return nodeInstance.get(`/api/courseFormUserEnrolmentCourse/${id}`);
};

coursesService.getCourseFormUserEnrolmentCourseByFormUserEnrolment = (id) => {
  return nodeInstance.get(`/api/courseFormUserEnrolmentCourse/byFormEnrollCourse/${id}`);
};

coursesService.getCoursesRecommendationsByWord = (word) => {
  return phpInstance.get(`/courses/recommendation/search/${word}`);
};

coursesService.getOvisTemplates = () => {
  return nodeInstance.get(`/api/ovisTemplate`);
};
coursesService.getOvisTemplate = (id) => {
  return nodeInstance.get(`/api/ovisTemplate/${id}`);
};
coursesService.getOvisWord = (word) => {
  return nodeInstance.get(`/api/ovis/find${word}`);
};
coursesService.getOvisUserWord = (word) => {
  return nodeInstance.get(`/api/ovis/findMyUser`, { params: { word } });
};
coursesService.getOvisWord = (word) => {
  return nodeInstance.get(`/api/formEnrollCourse/search/${word}`);
};

coursesService.validatePreEnrollment = (data) => {
  return nodeInstance.post(
    `/api/preEnrollCourse/validateCreatePreEnrollment`, data
  );
};

coursesService.getPreEnrollCourseByWord = (word) => {
  return nodeInstance.get(`/api/preEnrollCourse/search/${word}`);
};

coursesService.getPreEnrollCourse= (id) => {
  return nodeInstance.get(`/api/preEnrollCourse/${id}`);
};

coursesService.updatePreEnrollCourse= (id, data) => {
  return nodeInstance.patch(`/api/preEnrollCourse/${id}`, data);
};

coursesService.generateCertificate = (data) => {
  data.token = store.getters.getToken;
  return nodeInstance.post(`/api/certificate`, data);
};

coursesService.getPreEnrollCourseDefault = () => {
  const params = {
    skip: 0,
    take: 15
  };
  return nodeInstance.get("/api/preEnrollCourse", {params} );
};
coursesService.getFormPreEnrollInformation = (id) => {
  return nodeInstance.get(`/api/courseFormUserEnrolmentCourse/formEnrollCourse/${id}`);
};

coursesService.linkEnrollFormWithCourse = (data) => {
  return nodeInstance.post(`/api/courseFormUserEnrolmentCourse`, {...data});
};

coursesService.getAllCoursesFormNames = () => {
  return nodeInstance.get(`/api/formEnrollCourse`);
};

coursesService.getFormCourseInformation= (id) => {
  return nodeInstance.get(`/api/formEnrollCourse/${id}`);
};

coursesService.getAllCoursesForm = () => {
  const params = {
    skip: 0,
    take: 15
  };
  return phpInstance.get(`/courses/formEnrollCourse/`, {params});
};

coursesService.getCoursesCompleted = () => {
  return phpInstance.get("/moodle/courses/finished");
};

coursesService.getCoursesCompleted = () => {
  return phpInstance.get("/moodle/courses/finished");
};
coursesService.getCourseHistory = () => {
  return phpInstance.get("/institutions/courses/all");
};

coursesService.getByCourseOrCategory = search => {
  return phpInstance.get("/courses/search/" + search);
};

coursesService.getCategoriesByInstitution = () => {
  return phpInstance.get("/institutions/categoriesForInstitution");
};

coursesService.returnProgramForInstitutions = () => {
  return phpInstance.get("/institutions/program");
};

coursesService.getContinuingEducationCategorys = () => {
  return phpInstance.get("/courses/category/educationContinue");
};

coursesService.getByCategories = () => {
  const params = {
    skip: 0,
    take: 25
  };
  return phpInstance.get("/category", {params});
};

coursesService.getBySubCategories = () => {
  const params = {
    skip: 0,
    take: 50
  };
  return phpInstance.get("/subcategory" , {params});
};

coursesService.getContinuosEducationCourses = (take = 2, skip = 0) => {
  return phpInstance.get(
    "/courses/user/educationContinue/list/" + skip + "/" + take
  );
};

coursesService.getMyCourses = (take = 30, skip = 0) => {
  return phpInstance.get("/courses/user/" + skip + "/" + take);
};

coursesService.getAllMyCourses = () => {
  return phpInstance.get("/courses/user");
};
coursesService.getLastCoursesGeneral = (take = 20, skip = 0) => {
  return phpInstance.get("/courses/list/" + skip + "/" + take);
};

coursesService.getGeneralCoursesList = (take = 200000, skip = 0) => {
  return phpInstance.get("/courses/list/" + skip + "/" + take );
};

coursesService.getCoursesByProgram = (program, idInstitution) => {
  return phpInstance.get("/courses/findByProgram/" + program + "/" + idInstitution);
};

coursesService.getCountStudentsByInstitution = (idInstitution, role) => {
  return phpInstance.get(`/connection/countUsersByRolInstitution/${idInstitution}/${role}`);
};

coursesService.getTeacherList = (idInstitution) => {
  return phpInstance.get(`/user/getUserTeacher/${idInstitution}`);
};

coursesService.getCountUsersTeacherAndInvestigator = (idInstitution) => {
  return phpInstance.get("/user/countUsersTeacherAndInvestigator/" + idInstitution);
};

coursesService.getCoursesByCategory = categoryId => {
  return phpInstance.get("/courses/category/findById/" + categoryId);
};

coursesService.getInstitutions = () => {
  return phpInstance.get("/institutions/user");
};

coursesService.returnCoursesForProgram = id => {
  return phpInstance.get("/courses/program/" + id);
};

coursesService.getInstitutionsUrlMoodle = id => {
  return phpInstance.get(`/moodle/institutionUrl/${id}`);
};

coursesService.getLastActivities = obj => {
  obj.token = store.getters.getToken;
  return nodeInstance.get("/api/activities", { params: obj });
};

coursesService.getRecommendedCourses = () => {
  const params = { token: store.getters.getToken };
  return nodeInstance.get("/api/recommended", { params });
};

coursesService.getCourse = courseId => {
  return phpInstance.get(`/courses/findByCodigo/${courseId}`);
};

coursesService.getCourseById = (courseId) => {
  return phpInstance.get(`/courses/findById/${courseId}`);
};

coursesService.findCourse = code => {
  return phpInstance.get(`/courses/findByCodigo/${code}`);
};

coursesService.updateRecommendedCourse = (id, infoRecommendationCourse) => {
  return phpInstance.put(
    `/courses/recommendation/${id}}`, infoRecommendationCourse
  );
};
coursesService.editCourseForm = (id, infoCourse) => {
  return nodeInstance.patch(
    `/api/formEnrollCourse/${id}`, infoCourse
  );
};

coursesService.updateCourseSubcategoria = (codeCourse, idSubactegory) => {
  return phpInstance.put(
    `/courses/category/subcategoria/${codeCourse}/${idSubactegory}`
  );
};

coursesService.updateCourseCategoria = (codeCourse, idCategory) => {
  return phpInstance.put(
    `/courses/category/categoria/${codeCourse}/${idCategory}`
  );
};

coursesService.updateCourseImage = (codeCourse, url) => {
  return phpInstance.put(
    `/courses/imagen/${codeCourse}`,
    url
  );
};

coursesService.getUrlCourseMoodle = code => {
  return phpInstance.get(`/moodle/courseUrl/${code}`);
};

coursesService.qualificationCourses = rating => {
  return phpInstance.post(`/courses/qualificationCourse`, rating);
};

coursesService.getRecommended = () => {
  return phpInstance.get("/courses/recommendation");
};

coursesService.preEnrollCourseByStatus = (status = 'agree')  => {
  return nodeInstance.get(`/api/preEnrollCourse/byStatus/${status}` );
};

coursesService.createCourseForm = infoCourseForm  => {
  return nodeInstance.post(`/api/formEnrollCourse`, infoCourseForm );
};

coursesService.createRecommendedCourse = infoRecommendationCourse => {
  return phpInstance.post(`/courses/recommendation/`, infoRecommendationCourse);
};

coursesService.createPreEnrollCourse = infoPreEnrollCourse => {
  return nodeInstance.post(`/api/preEnrollCourse`, infoPreEnrollCourse);
};

coursesService.createRecommended = infoRecommendation => {
  return phpInstance.post(`/courses/recommendation`, infoRecommendation);
};

coursesService.qualificationCourses = rating => {
  return phpInstance.post(`/courses/qualificationCourse`, rating);
};

coursesService.qualificationCourses = rating => {
  return phpInstance.post(`/courses/qualificationCourse`, rating);
};

coursesService.createRecommended = infoRecommendation => {
  return phpInstance.post(`/courses/recomendation`, infoRecommendation);
};

coursesService.deleteRecommendedCourse = (id) => {
  return phpInstance.delete(
    `/courses/recommendation/${id}}`
  );
};

coursesService.deleteFormEnrollCourse = (id) => {
  return nodeInstance.delete(
    `/api/formEnrollCourse/${id}`
  );
};

coursesService.uploadFile = (folder, subFolder, name, file) => {
  const route = !subFolder ? `${folder}` : `${folder}/${subFolder}`;
  return storage
    .child(`${route}/${name}`)
    .put(file)
    .then(async snapshot => {
      const url = await snapshot.ref.getDownloadURL();
      return url;
    });
};

coursesService.createRecommended = infoRecommendation => {
  return phpInstance.post(`/courses/recommendation`, infoRecommendation);
};

coursesService.createRecommended = infoRecommendation => {
  return phpInstance.post(`/courses/recommendation`, infoRecommendation);
};

export default coursesService;
